import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-456f28cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.handleCreateRole
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("permission.createRole")), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_el_table, {
      data: _ctx.rolesList,
      style: {"width":"100%","margin-top":"30px"},
      border: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          align: "center",
          label: "Role Key",
          width: "220"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.key), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          label: "Role Name",
          width: "220"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.name), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "header-center",
          label: "Description"
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.description), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          label: "Operations"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              type: "primary",
              size: "small",
              onClick: ($event: any) => (_ctx.handleEdit(scope))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("permission.editPermission")), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              type: "danger",
              size: "small",
              onClick: ($event: any) => (_ctx.handleDelete(scope))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("permission.delete")), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: _ctx.dialogType === 'edit' ? 'Edit Role' : 'New Role'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.role,
          "label-width": "80px",
          "label-position": "left"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Name" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.role.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role.name) = $event)),
                  placeholder: "Role Name"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Desc" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.role.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.role.description) = $event)),
                  autosize: {minRows: 2, maxRows: 4},
                  type: "textarea",
                  placeholder: "Role Description"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Menus" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tree, {
                  ref: "treeRef",
                  "check-strictly": _ctx.checkStrictly,
                  data: _ctx.routesTreeData,
                  props: _ctx.defaultProps,
                  "show-checkbox": "",
                  "node-key": "path",
                  class: "permission-tree"
                }, null, 8, ["check-strictly", "data", "props"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("permission.cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.confirmRole
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("permission.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}